@import '../../theme/theme';

.fullPageOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: $transparent-overlay;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-animation: fadein 0.4s;
          animation: fadein 0.4s;
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.extraTopMargin {
  margin-top: $grid-row-height * 2;
}

.loader,
.loader:after {
  border-radius: 50%;
}

.loader {
  position: relative;
  border: 4px solid rgba(147, 147, 147, 0.2);
  border-left: 4px solid $grey-500;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation: load 0.8s infinite linear;
          animation: load 0.8s infinite linear;
}

.fullPageOverlay .loader {
  border: 8px solid rgba(255, 255, 255, 0.2);
  border-left: 8px solid $grey-200;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
