@import './common/theme/theme';

@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&display=swap);

:global {
  @import './design-system/components/Slider/Slider.scss';
  @import './rte.scss';
  @import './paging.scss';
}

:global html {
    font-family: $base-font-stack;
    color: $grey-900;
    font-weight: $font-weight-regular;
    font-size: $base-font-size;
    background-color: $grey-100;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-height: 100%;
    width: 100%;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
            text-size-adjust: 100%;
    /* Prevent font scaling in landscape mobile view */
  }

:global *,:global *:before,:global *:after {
    font-family: inherit;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

:global *:focus {
    outline: $blue-500 solid 1px;
    outline-offset: 2px;
    z-index: 3000;
  }

:global input {
    margin: 0;
  }

:global body {
    margin: 0;
    font-size: $font-standard;
    letter-spacing: $letter-spacing-standard;
    min-height: 100%;
    width: 100%;
  }

:global a {
    color: $blue-600;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
  }

:global a:hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }