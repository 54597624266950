@import '../../../theme/theme';

.root {
  max-width: $form-grow-max-width;
}

.longField {
  max-width: 100%;
}

.shortField {
  max-width: 120px;
}

.extraTopMargin {
  margin-top: $grid-row-height * 2;
}

.firstField .label {
    margin-top: 0;
  }

.input {
  @include formElementBorder();
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text; /* required to ensure input field text appears for older ios devices */
  padding: 14px $default-horizontal-padding 13px $default-horizontal-padding;
  width: 100%;
  font-size: $font-standard;
  letter-spacing: $letter-spacing-standard;
  font-weight: $font-weight-light;
  resize: vertical;
}

.input:focus {
    @include formElementFocusState();
  }

.input::-webkit-input-placeholder {
    color: $grey-600;
    font-size: $font-small;
    letter-spacing: $letter-spacing-small;
    line-height: $grid-row-height * 2;
  }

.input::-moz-placeholder {
    color: $grey-600;
    font-size: $font-small;
    letter-spacing: $letter-spacing-small;
    line-height: $grid-row-height * 2;
  }

.input:-ms-input-placeholder {
    color: $grey-600;
    font-size: $font-small;
    letter-spacing: $letter-spacing-small;
    line-height: $grid-row-height * 2;
  }

.input::-ms-input-placeholder {
    color: $grey-600;
    font-size: $font-small;
    letter-spacing: $letter-spacing-small;
    line-height: $grid-row-height * 2;
  }

.input::placeholder {
    color: $grey-600;
    font-size: $font-small;
    letter-spacing: $letter-spacing-small;
    line-height: $grid-row-height * 2;
  }

.input:disabled {
    @include formElementDisabledState();
  }

.forceFocus {
  @extend .input;
  @include formElementFocusState();
}

.inputInvalid {
  @extend .input;
  @include formElementInvalidState();
}

.inputInvalid:focus {
    @include formElementFocusState();
  }

.charCounter {
  text-align: right;
  font-size: $font-small;
  letter-spacing: $letter-spacing-small;
  color: $grey-600;
}

.charCounterOver {
  @extend .charCounter;
  color: $red-600;
}
