@import '../palette/colours';

@mixin hideSelectionFormElement() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin formElementBorder($withRadius: true) {
  border: 1px solid $grey-500;
  @if $withRadius {
    border-radius: 6px;
  }
}

@mixin formElementInvalidState() {
  border: 1px solid $red-600;
}

@mixin formElementFocusState() {
  outline: 0;
  border: 1px solid $green-700;
}

@mixin formElementDisabledState() {
  outline: 0;
  border: 1px solid $green-200;
}