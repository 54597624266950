@import '../../theme/theme';

.root {
  color: $red-600;
  font-size: $font-small;
  letter-spacing: $letter-spacing-small;
  margin: ($grid-row-height * 0.5) 0;
  line-height: 18px;
}

.outsideCard {
  @extend .root;

  font-weight: $font-weight-light;
  font-size: $font-small;
  letter-spacing: $letter-spacing-small;

  padding: 0 $small-device-padding 0 $small-device-padding;
}

@media screen and (min-width: $form-grow-max-width) {

.outsideCard {
    padding-left: $medium-device-padding;
    padding-right: $medium-device-padding;
}
  }

.extraTopMargin {
  margin-top: $grid-row-height * 2;
}

.errorItem {
  margin-top: $grid-row-height * 2;
}

.errorItem:first-of-type {
    margin-top: 0;
  }

.icon {
  @include validationIconSvgStyling();
}

.errorText {
  @include formValidationText();
}
