@import '../../theme/theme';

.button {
  /* reset browser button styles */
  background: none;
  border: 1px;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  font-size: $font-standard;
  letter-spacing: $letter-spacing-standard;
  font-weight: $font-weight-regular;
  padding: 14px $default-horizontal-padding 13px $default-horizontal-padding;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color 0.2s, -webkit-transform .2s cubic-bezier(0.020, 1.505, 0.745, 1.235);
  transition: background-color 0.2s, -webkit-transform .2s cubic-bezier(0.020, 1.505, 0.745, 1.235);
  transition: background-color 0.2s, transform .2s cubic-bezier(0.020, 1.505, 0.745, 1.235);
  transition: background-color 0.2s, transform .2s cubic-bezier(0.020, 1.505, 0.745, 1.235), -webkit-transform .2s cubic-bezier(0.020, 1.505, 0.745, 1.235);
  width: calc(100% - #{$small-device-padding * 2});
  margin: ($grid-row-height * 2) $small-device-padding 0 $small-device-padding;
  display: inline-block;
}

.button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

@media screen and (min-width: $form-grow-max-width) {

.button {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
}
  }

.insideCard {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: $form-grow-max-width) {

.insideCard {
    width: auto;
}
  }

.extraTopMargin {
  margin-top: $grid-row-height * 4;
}

.none {
  @extend .button;
}

.primary {
  @extend .button;
  border: 1px solid $green-700;
  color: $white;
  background-color: $green-700;
}

.primary:hover {
    color: $white;
    -webkit-text-decoration: none;
    text-decoration: none;
    background: lighten($green-700, 5%);
  }

.primary:active {
    color: $white;
    -webkit-text-decoration: none;
    text-decoration: none;
    background: darken($green-700, 5%);
  }

.secondary {
  @extend .button;
  color: $green-700;
  border: 1px solid $green-700;
  background-color: $white;
}

.secondary:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: $grey-50;
  }

.secondary:active {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: $grey-200;
  }

.ghost {
  @extend .button;
  border: 1px solid $white;
  color: $white;
}

.ghost:active,.ghost:hover,.ghost:visited {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba($white, 0.1);
  }

.loadingContainer {
  height: $grid-row-height * 8;
  overflow-y: hidden;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
