@import '../../../theme/theme';

.extraTopMargin {
  margin-top: $grid-row-height * 2;
}

.root,
.invalid {
  position: relative;
  max-width: $form-grow-max-width;
}

.invalid input {
  @include formElementInvalidState();
}

.invalid input:focus {
    @include formElementFocusState();
  }

.label {
  display: block;
  line-height: 23px;
  margin-bottom: 4px;
}

.input {
  @include formElementBorder();
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 13px 45px 11px 15px;
  width: 100%;
  font-size: $font-standard;
  letter-spacing: $letter-spacing-standard;
  font-weight: $font-weight-light;
}

.input:focus {
    @include formElementFocusState();
  }

.input:disabled {
    @include formElementDisabledState();
  }

.suggestionsContainerOpen {
  @include formElementBorder();
  border-top: none;
  line-height: $grid-row-height * 3;
  position: absolute;
  background: url(/images/logos/powered-by-google-with-border.png) no-repeat bottom right $white;
  width: 100%;
  padding-bottom: $grid-row-height * 4;
  z-index: 1000;
}

.suggestion {
  padding: 9px $default-horizontal-padding;
  max-height: 83px; /* prevent text from going to 4 rows if address super long! */
  overflow: hidden;
}

.suggestionHighlighted {
  background: $grey-100;
}

.highlightSuggestion {
  font-weight: $font-weight-regular;
}

.searchSpinner {
  position: absolute;
  top: 11px;
  right: 13px;
}

.searchIcon {
  position: absolute;
  top: 14px;
  right: 13px;
  width: 22px;
  height: 22px;
}
