@import '../../../../../common/theme/theme';

$text-color: $grey-900;

.centered {
  margin: 0 auto;
  width: 100%;
  max-width: $layout-grow-max-width;
  min-width: $small-screen-min-width - ($small-device-padding * 2);
}

.footer {
  background: $grey-200;
  padding: 15px $small-device-padding;
  line-height: 28px;
  min-width: $small-screen-min-width;
}

@media screen and (min-width: $layout-grow-max-width + ($small-device-padding * 2)) {

.footer {
    text-align: center;
}
  }

.footer a {
    display: block;
    color: $text-color;
    padding-bottom: 2px;
    font-size: $font-small;
    letter-spacing: $letter-spacing-small;
    font-weight: $font-weight-light;
  }

@media screen and (min-width: $layout-grow-max-width + ($small-device-padding * 2)) {

.footer a {
      display: inline-block;
      padding: 0 12px;
  }
    }

.wrapper {
  @extend .centered;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media screen and (min-width: $layout-grow-max-width + ($small-device-padding * 2)) {

.wrapper {
    display: block;
}
  }

.copyright {
  @extend .centered;
  color: $text-color;
  font-size: $font-small;
  letter-spacing: $letter-spacing-small;
  padding-top: $grid-row-height;
}
