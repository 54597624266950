@import '../../theme/theme';

.toggle {
  position: absolute;
  display: inline-block;
  top: 30px;
  right: 5px;
  z-index: 2000;
  color: $white !important;
  font-size: $font-small;
  letter-spacing: $letter-spacing-small;
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

@media screen and (min-width: $medium-screen-min-width) {

.toggle {
    top: 55px;
    right: 10px;
    color: $grey-900 !important;
}
  }

.grid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  pointer-events: none;
  opacity: 0.4;
}

.row {
  margin: 0 auto;
  width: $grid-container-width + (2 * $grid-container-margin);
  clear: both;
}

.margin {
  float: left;
  border-bottom: 1px solid #00FF00;
  width: $grid-container-margin;
  height: $grid-row-height;
}

.column {
  float: left;
  border-bottom: 1px solid #FF66FF;
  width: $grid-column-width;
  height: $grid-row-height;
}

.gutter {
  float: left;
  border-bottom: 1px solid #FF6600;
  width: $grid-gutter-width;
  height: $grid-row-height;
}
