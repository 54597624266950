@import '../typography/type';

$validation-icon-margin: 5px;

@mixin validationIconSvgStyling() {
  display: inline-block;
  vertical-align: top;
  margin: 1px $validation-icon-margin 0 0;
  height: $grid-row-height * 2;
  width: $grid-row-height * 2;
}

@mixin formValidationText() {
  display: inline-block;
  width: calc(100% - (#{$font-standard} + #{$validation-icon-margin}));
  line-height: 18px;
}