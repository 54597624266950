@import '../../../theme/theme';

.root {
  color: $red-600;
  font-size: $font-small;
  letter-spacing: $letter-spacing-small;
  margin: ($grid-row-height * 0.5) 0;
}

.extraTopMargin {
  @extend .root;
  margin-top: $grid-row-height * 2;
}

.icon {
  @include validationIconSvgStyling();
}

.errorText {
  @include formValidationText();
}

.errorHelpMessage {
  @include formValidationText();
  font-size: $font-small;
  letter-spacing: $letter-spacing-small;
  color: $grey-600;
  padding-left: 18px;
}
