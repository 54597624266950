@import '../../theme/theme';

.extraTopMargin {
  display: block;
  margin-top: $grid-row-height * 2;
}

.extraLeftMargin {
  margin-left: $grid-row-height * 2;
}

.centered {
  display: block;
  text-align: center;
}

.icon {
  display: inline-block;
  vertical-align: top;
  height: $font-standard;
  width: $font-standard;
  margin: 0 5px 3px 0;
}
