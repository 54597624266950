@import '../../theme/theme';

.text {
  font-size: $font-tiny;
  letter-spacing: $letter-spacing-tiny;
  font-weight: $font-weight-light;
  line-height: $grid-row-height * 2;
}

.reducedTopMargin {
  margin-top: -4px;
}