// Base
$base-font-stack: 'Noto Sans', sans-serif;
$base-font-size:  62.5%; //equiv 10px used as base for logical rem values. eg. 14px = 1.4rem etc.

// Font size
$font-tiny:      1.0rem;
$font-mini:      1.2rem;
$font-small:     1.4rem;
$font-standard:  1.6rem;
$font-large:     2.0rem;
$font-xlarge:    2.4rem;

// Font weight
$font-weight-light:   300;
$font-weight-regular: 400;
$font-weight-medium:  500;
$font-weight-bold:    700;

// Type styles
$heading-1:  2.4rem;
$heading-2:  2.0rem;
$body-1:     1.6rem;
$body-2:     1.4rem;
$paragraph:  1.0rem;

// Letter spacing
$letter-spacing-tiny:     -0.02em;
$letter-spacing-mini:     -0.02em;
$letter-spacing-small:    -0.04em;
$letter-spacing-standard: -0.04em;
$letter-spacing-large:    -0.06em;
$letter-spacing-xlarge:   -0.08em;
