// Primary colour
$green-50:                #F2FDF7; // Used as shadows to add depth to brand coloured icons/text
$green-100:               #DFF8EB;
$green-200:               #BDF0D5;
$green-500:               #22C365;
$green-600:               #15A449;
$green-700:               #0E8136;
$green-800:               #085C22;

// Secondary colours
$red-50:                  #FFF1F1;
$red-100:                 #FFDFDF;
$red-200:                 #FFBEBE;
$red-500:                 #FF3333;
$red-600:                 #E32121; // Used for errors

$yellow-50:               #FFFDF2;
$yellow-100:              #FFF7E1;
$yellow-200:              #FFEEC3;
$yellow-500:              #FFBB33;
$yellow-600:              #E28D21;

$blue-50:                 #F2FAFE;
$blue-100:                #DEF0FC;
$blue-200:                #B9DEF8;
$blue-500:                #197FE6;
$blue-600:                #0F62C7; // Used for links

$purple-50:               #F8F3FE;
$purple-100:              #ECE2FC;
$purple-200:              #D8C3F9;
$purple-500:              #6E30E8;
$purple-600:              #521ECA;

// Neutral
$grey-50:                 #FAFAFA;
$grey-100:                #F0F0F0;
$grey-200:                #DEDEDE;
$grey-300:                #C1C1C1;
$grey-400:                #A4A4A4;
$grey-500:                #808080;
$grey-600:                #626262; // Lightest text colour allowed on white bg
$grey-700:                #494949;
$grey-900:                #1C1C1C;

$white:                   #FFFFFF;
$black:                   #000000;

// Transparencies
$transparent-overlay: rgba(23,26,23,0.5);

// Shadows 
$small-box-shadow: 0px 4px 8px $grey-200;
$medium-box-shadow: 0px 4px 16px $grey-300;
$large-box-shadow: 0px 4px 24px $grey-400;