@import '../../theme/theme';

$small-margin: $grid-row-height;
$medium-margin: $grid-row-height * 2;
$large-margin: $grid-row-height * 4;

@mixin flex-direction($flex-direction) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: $flex-direction;
      -ms-flex-direction: $flex-direction;
          flex-direction: $flex-direction;
}

@mixin flex-direction-media-query($flex-direction, $min-width) {
  @media screen and (min-width: $min-width) {
    @include flex-direction($flex-direction);
  }
}

@mixin justify-content($justify-content) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: $justify-content;
  -webkit-justify-content: $justify-content;
      -ms-flex-pack: $justify-content;
          justify-content: $justify-content;
}

@mixin justify-content-media-query($justify-content, $min-width) {
  @media screen and (min-width: $min-width) {
    @include justify-content($justify-content);
  }
}

@mixin align-items($align-items) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: $align-items;
  -webkit-align-items: $align-items;
      -ms-flex-align: $align-items;
          align-items: $align-items;
}

@mixin align-items-media-query($align-items, $min-width) {
  @media screen and (min-width: $min-width) {
    @include align-items($align-items);
  }
}

.isPageContainer {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.flexWrap-wrap {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.flexDirection-row { @include flex-direction(row) }
.flexDirection-column { @include flex-direction(column) }
.flexDirection-row-tablet { @include flex-direction-media-query(row, $medium-screen-min-width) }

.justifyContent-spaceBetween { @include justify-content(space-between) }
.justifyContent-spaceAround { @include justify-content(space-around) }
.justifyContent-flexEnd { @include justify-content(flex-end) }
.justifyContent-flexStart { @include justify-content(flex-start) }
.justifyContent-spaceBetween-tablet { @include justify-content-media-query(space-between, $medium-screen-min-width) }
.justifyContent-flexStart-tablet { @include justify-content-media-query(flex-start, $medium-screen-min-width) }

.alignItems-center { @include align-items(center) }
.alignItems-stretch { @include align-items(stretch) }
.alignItems-center-tablet { @include align-items-media-query(center, $medium-screen-min-width) }

.textAlign-left { text-align:  left }
.textAlign-right { text-align:  right }
.textAlign-center { text-align:  center }

.marginLeft-auto { margin-left: auto }
.marginLeft-medium { margin-left: $medium-margin }

.marginTop-small { margin-top: $small-margin }
.marginTop-medium { margin-top: $medium-margin }
.marginTop-large { margin-top: $large-margin }

.marginBottom-small { margin-bottom: $small-margin }
.marginBottom-medium { margin-bottom: $medium-margin }
.marginBottom-large {margin-bottom: $large-margin }

.marginRight-small { margin-right: $small-margin }
.marginRight-medium { margin-right: $medium-margin }