.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
}

.loaderWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
}
