.paging-menu {
  .next, .previous {
    display: none;
  }

  li.active {
    a {
      background-color: hsl(var(--twc-foreground-brand));
      border-color: hsl(var(--twc-foreground-brand));
      color: hsl(var(--twc-foreground-white));
    }
  }

  li:focus, a:focus {
    outline: none;
  }

  a {
    color: hsl(var(--twc-background-white));
  }
  .pagination {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    list-style: none;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination li a {
    cursor: pointer;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid hsl(var(--twc-background-midDark));
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination .break-me {
    cursor: default;
  }
}