@import '../../theme/theme';

.onDarkBackground {
  color: $white;
}

.onDarkBackground:hover,.onDarkBackground:active,.onDarkBackground:visited {
    color: $white;
  }

.display-block {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.display-block:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }

.extraTopMargin {
  margin-top: $grid-row-height * 2;
}