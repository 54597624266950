@import '../../theme/theme';

.container {
  width: 100%;
}

@media screen and (min-width: $form-grow-max-width) {

.container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}
  }

.centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.secondaryActionContainer {
  margin-top: 0px;
}

@media screen and (min-width: $form-grow-max-width) {

.secondaryActionContainer {
    margin-top: $grid-row-height * 2;
}
  }

.sideLink {
  display: block;
  text-align: center;
  margin-top: 18px;
  font-weight: $font-weight-light;
}

@media screen and (min-width: $form-grow-max-width) {

.sideLink {
    text-align: left;
    margin-left: $grid-row-height * 3;
}
  }

@media screen and (min-width: $form-grow-max-width) {

.secondaryButton {
    margin-left: $grid-row-height * 2 !important;
}
  }
