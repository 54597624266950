$green-active: #0e8136;
$green-disabled: #9ecdaf;

.inputNumber {
  .ant-input-number:focus-within {
    border-color: $green-active;
    box-shadow: none;
  }
}

.slider {
  @mixin slider-handle {
    background-color: white;
    box-shadow: 0 0 0 1px $green-active;
    outline: 0;
    transform: translateY(-33.3%);
    height: 24px;
    width: 24px;
    inset-inline-start: 0;
    inset-block-start: 0;
  }

  .ant-slider-mark-text-active,
  .ant-slider-mark-text {
    margin-top: 8px;
  }

  .ant-slider-step {
    .ant-slider-dot {
      border-color: #dedede;
      background-color: #dedede;
    }

    .ant-slider-dot-active {
      border-color: $green-active;
      background-color: $green-active;
    }
  }

  .ant-slider-handle {
    &::after,
    &:active::after,
    &:hover::after,
    &:focus::after {
      @include slider-handle;
    }
  }

  .ant-slider:hover {
    .ant-slider-handle {
      &::after {
        @include slider-handle;
      }
    }
  }

  .ant-slider-disabled {
    &:hover {
      .ant-slider-handle {
        &::after {
          @include slider-handle;
          box-shadow: 0 0 0 1px $green-disabled;
        }
      }
    }
    .ant-slider-handle {
      &::after,
      &:hover::after,
      &:focus::after {
        @include slider-handle;
        box-shadow: 0 0 0 1px $green-disabled;
      }
    }

    .ant-slider-rail {
      background-color: $green-disabled !important;
    }

    .ant-slider-dot-active {
      background-color: $green-disabled;
      border-color: $green-disabled;
    }
  }
}
