@import '../../../common/theme/theme';

.extraTopMargin {
  margin-top: $grid-row-height * 2;
}

.fieldContainer {
  position: relative;
}

.iconContainer {
  position: absolute;
  right: $grid-row-height;
  top: $grid-row-height * 4.5;
}

@media screen and (min-width: $form-grow-max-width) {

.iconContainer {
    right: $grid-container-margin * 10;
}
  }
